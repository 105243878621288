const burger_btn = document.querySelector('.navbar__burger-btn')
const nav_items = document.querySelector('.navbar__list')
const nav_item = document.querySelectorAll('.navbar__item')
const footerYear = document.querySelector('.footer__year')

const navToggler = () => {
	nav_items.classList.toggle('showNav')
}

nav_item.forEach(item => {
	item.addEventListener('click', () => {
		nav_items.classList.remove('showNav')
	})
})

const handleCurrentYear = () => {
	const year = new Date().getFullYear()
	footerYear.innerText = year
}

handleCurrentYear()
burger_btn.addEventListener('click', navToggler)
